import { EventType, getBaseTracksValues } from '@/components/tracks'

import { SupplyQuery } from './supply.gateway'

const toSearchQueryType = (query: SupplyQuery): 'query' | 'region' | 'logradouro' => {
    if (query.address) return 'logradouro'
    if (query.regions?.length) return 'region'
    return 'query'
}

const toSearchText = (query: SupplyQuery) => {
    if (query.address) return query.address
    if (query.regions?.length) return query.regions.map((r) => r.bairro).join(', ')
    return ''
}

type ClosedRangeValue = readonly [number, number]
type OpenRangeValue = readonly [number, undefined]
type RangeValue = ClosedRangeValue | OpenRangeValue

const extractRange = (rangeValue: string | undefined): RangeValue | undefined => {
    if (!rangeValue) return undefined

    const [min, max] = rangeValue.split('_').map(Number)
    return [min, max]
}

const extractCommaSeparatedList = (value: string | undefined): string[] | undefined => {
    if (!value) return undefined
    return value.split(',').map((v) => v.trim())
}

const getFilterValue = (query: SupplyQuery, filterName: string): string | undefined =>
    query.filters?.find((f) => f.name === filterName)?.value

const mapRangeToName = (density: RangeValue | undefined) => {
    if (!density) return undefined

    const [_, max] = density
    if (max === 2) return 'low'
    if (max === 3) return 'mid'
    if (max === 4) return 'high'
    return undefined
}

export const buildListingSearchParams = (query: SupplyQuery) => {
    const searchQueryType = toSearchQueryType(query)
    const searchQueryText = toSearchText(query)
    const searchPrice = extractRange(getFilterValue(query, 'preço'))
    const searchSize = extractRange(getFilterValue(query, 'tamanho-do-imovel'))
    const searchDensity = mapRangeToName(extractRange(getFilterValue(query, 'densidade')))
    const searchPedestrianFlux = mapRangeToName(extractRange(getFilterValue(query, 'fluxo-pedestre')))
    const searchVehicleFlux = mapRangeToName(extractRange(getFilterValue(query, 'fluxo-veículo')))
    const searchSupplyType = extractCommaSeparatedList(getFilterValue(query, 'tipo-de-imovel'))
    const searchVacancies = extractRange(getFilterValue(query, 'vagas'))
    const searchCorner = getFilterValue(query, 'Esquina')
    const searchBathrooms = extractRange(getFilterValue(query, 'banheiros'))
    const searchItWas = extractCommaSeparatedList(getFilterValue(query, 'antes-era'))
    const searchSort = query.sort?.name

    return [
        EventType.ListingSearch,
        {
            ...getBaseTracksValues(),
            searchQueryType,
            searchQueryText,
            searchPriceMin: searchPrice?.[0],
            searchPriceMax: searchPrice?.[1],
            searchSizeMin: searchSize?.[0],
            searchSizeMax: searchSize?.[1],
            searchDensity,
            searchPedestrianFlux,
            searchVehicleFlux,
            searchVacancies: searchVacancies?.[0],
            searchBathrooms: searchBathrooms?.[0],
            searchItWas,
            searchCorner,
            searchSupplyType,
            searchSort,
        },
    ] as const
}
